@if (resumeData) {
<div class="resume" #resumeContent>
    <div class="SidePanel">
        <div class="ProfileImage">
            <img src="{{resumeData.ProfileImage}}" alt="Profile Picture">
        </div>

        <div class="contact Panel">
            <div class="Header">{{ languageService.getTranslation('Contact') }}</div>
            <!-- example.component.html -->
            <div class="contact-list">
                <p>
                    <span class="icon">
                        <i class="material-icons-outlined">home</i>
                    </span>
                    <span class="data" [innerHTML]="resumeData.Contact.Address.replaceAll(', ',',\<br\>')"></span>
                </p>
                <p>
                    <span class="icon">
                        <i class="material-icons-outlined">mail</i>
                    </span>
                    <span class="data">
                        <a href="mailto:{{ resumeData.Contact.Email }}">{{ resumeData.Contact.Email }}</a>
                    </span>
                </p>
                <p>
                    <span class="icon">
                        <i class="material-icons-outlined">phone</i>
                    </span>
                    <span class="data">{{ resumeData.Contact.Phone }}</span>
                </p>
                <p>
                    <span class="icon">
                        <i class="material-icons-outlined">language</i>
                    </span>
                    <span class="data">
                        <a href="http://{{ resumeData.Contact.Website }}">{{ resumeData.Contact.Website }}</a>
                    </span>
                </p>
                <p>
                    <span class="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" fill="white"
                            viewBox="0 0 50 50">
                            <path
                                d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M17,20v19h-6V20H17z M11,14.47c0-1.4,1.2-2.47,3-2.47s2.93,1.07,3,2.47c0,1.4-1.12,2.53-3,2.53C12.2,17,11,15.87,11,14.47z M39,39h-6c0,0,0-9.26,0-10 c0-2-1-4-3.5-4.04h-0.08C27,24.96,26,27.02,26,29c0,0.91,0,10,0,10h-6V20h6v2.56c0,0,1.93-2.56,5.81-2.56 c3.97,0,7.19,2.73,7.19,8.26V39z">
                            </path>
                        </svg>
                    </span>
                    <span class="data">
                        <a href="{{ resumeData.Contact.LinkedIn_URL }}">{{ resumeData.Contact.LinkedIn_Preview }}</a>
                    </span>
                </p>
                <p>
                    <span class="icon">
                        <i class="material-icons-outlined">badge</i>
                    </span>
                    <span class="data">{{ resumeData.Contact.Driverlicense }}</span>
                </p>
            </div>

        </div>

        <div class="profile Panel">
            <div class="Header">{{ languageService.getTranslation('Profile') }}</div>
            <p class="ProfileText">{{ resumeData.Profile }}</p>
        </div>

        <div class="Panel">
            <div class="Header">
                <div class="Title">{{ languageService.getTranslation('Skills') }}</div>
                <div class="SubTitle">{{ languageService.getTranslation('ProgrammingLanguage') }}</div>
            </div>
            @for (skill of resumeData.Skills.Programming_Languages; track $index)
            {
            <div class="Skill">
                <div class="Name">{{ skill.Name }}</div>
                <div class="progress-bar">
                    <div class="progress-fill" [style.width]="skill.Value + '%'"></div>
                </div>
            </div>
            }
        </div>

        <div class="Panel">
            <div class="Header">
                <div class="Title">{{ languageService.getTranslation('Skills') }}</div>
                <div class="SubTitle">{{ languageService.getTranslation('Language') }}</div>
            </div>
            @for (language of resumeData.Skills.Languages; track $index)
            {
            <div class="Skill">
                <div class="Name">{{ language.Name }}</div>
                <div class="progress-bar">
                    <div class="progress-fill" [style.width]="language.Value + '%'"></div>
                </div>
            </div>
            }
        </div>
    </div>
    <div class="MainPanel">
        <div class="HeaderBlock Panel">
            <h1 class="FirstName">{{ resumeData.FirstName }}</h1>
            <h1 class="Name">{{ resumeData.Name }}</h1>
            <h2 class="Title">{{ resumeData.Title }}</h2>
        </div>
        <div class="Panel">
            <div class="Header">{{ languageService.getTranslation('Education') }}</div>
            <div class="List">
                @for (edu of resumeData.Education; track $index)
                {
                <div class="Entry">
                    <div class="EntryHeader">
                        <div class="LeftSide">
                            <h4 class="Degree">{{ edu.Degree }}</h4>
                            <div class="Year">({{ edu.Year }})</div>
                        </div>
                        <div class="RightSide">
                            <div class="Company">{{ edu.School }}</div>
                            <div class="Location">{{ edu.Location }}</div>
                        </div>
                    </div>
                    <p class="Description">{{ edu.Description }}</p>
                </div>
                }
            </div>
        </div>

        <div class="Panel">
            <div class="Header">{{ languageService.getTranslation('WorkExperience') }}</div>
            <div class="List">
                @for (exp of resumeData.Experience; track $index)
                {
                <div class="Entry">
                    <div class="EntryHeader">
                        <div class="LeftSide">
                            <h4 class="Degree">{{ exp.Title }}</h4>
                            <div class="Year">({{ exp.Dates }})</div>
                        </div>
                        <div class="RightSide">
                            <div class="Company">{{ exp.Company }}</div>
                            <div class="Location">{{ exp.Location }}</div>
                        </div>
                    </div>
                    <p class="Description">{{ exp.Description }}</p>
                </div>
                }
            </div>
        </div>

        <div class="Panel">
            <div class="Header">{{ languageService.getTranslation('References') }}</div>
            <div class="References">
                @for (ref of resumeData.References; track $index)
                {
                <div class="ReferenceEntry">
                    <p class="Degree">{{ ref.Name }}</p>
                    <p class="Company">{{ ref.Title }}</p>
                    <div class="ContactInfo">
                        <span class="icon">
                            <i class="material-icons-outlined">phone</i>
                        </span>
                        <span class="Phone">{{ ref.Phone }}</span>
                    </div>
                    <div class="ContactInfo">
                        <span class="icon">
                            <i class="material-icons-outlined">email</i>
                        </span>
                        <span class="Email">
                            <a href="mailto:{{ ref.Email }}">{{ ref.Email }}</a>
                        </span>
                    </div>
                </div>
                }
            </div>
        </div>
    </div>
</div>
<button (click)="downloadResumePDF()">{{languageService.getTranslation('DownloadPDF')}}</button>
}